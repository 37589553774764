import React from 'react';
import FAQ from '../../FAQ';
import ContentSection from '../../ContentSection';
import { useTranslation } from 'react-i18next';

function KeywordsGrouperFAQ() {
  const { t } = useTranslation('KeywordGrouper');
  const items = [
    {
      question: t('KeywordsGrouperFAQ.item1.question'),
      answer: t('KeywordsGrouperFAQ.item1.answer'),
    },
    {
      question: t('KeywordsGrouperFAQ.item2.question'),
      answer: t('KeywordsGrouperFAQ.item2.answer'),
    },
    {
      question: t('KeywordsGrouperFAQ.item3.question'),
      answer: t('KeywordsGrouperFAQ.item3.answer'),
    },
    {
      question: t('KeywordsGrouperFAQ.item4.question'),
      answer: t('KeywordsGrouperFAQ.item4.answer'),
    },
    {
      question: t('KeywordsGrouperFAQ.item5.question'),
      answer: t('KeywordsGrouperFAQ.item5.answer'),
    },
  ];

  return (
    <ContentSection background={2}>
      <FAQ items={items} />
    </ContentSection>
  );
}

export default KeywordsGrouperFAQ;
