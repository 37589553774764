import React from 'react';

export const CloseButton = (props) => (
  <div className="modal-video-close-btn" {...props}>
    <svg
      role="img"
      viewBox="0 0 24 24"
      css={`
        fill: currentColor;
        vertical-align: middle;
        height: 1rem;
        width: 1rem;
      `}
    >
      <path
        d="M9.82 12L0 2.18 2.18 0 12 9.82 21.82 0 24 2.18 14.18 12 24 21.82 21.82 24 12 14.18 2.18 24 0 21.82z"
        fill="currentColor"
      />
    </svg>
  </div>
);
