import React from 'react';
import { useTranslation } from 'react-i18next';
import MetaTags from '../components/Seo/MetaTags';
import HeroKeywordGrouper from '../components/pages/KeywordGrouper/HeroKeywordGrouper';
import DistributeKeywords from '../components/pages/KeywordGrouper/DistributeKeywords';
import KeywordsCompetitiveness from '../components/pages/KeywordGrouper/KeywordsCompetitiveness';
import ClusterGroups from '../components/pages/KeywordGrouper/ClusterGroups';
import ClustersGroupsTools from '../components/pages/KeywordGrouper/ClustersGroupsTools';
import ExportClusters from '../components/pages/KeywordGrouper/ExportClusters';
import HowClusteringWork from '../components/pages/KeywordGrouper/HowClusteringWork';
import Pricing from '../components/Pricing';
import KeywordGrouperFeatures from '../components/pages/KeywordGrouper/Features';
import KeywordsGrouperFAQ from '../components/pages/KeywordGrouper/KeywordsGrouperFAQ';
import Brands from '../components/Brands';
import StructuredService from '../components/Seo/StructuredService';
import StructuredAggregateRating from '../components/Seo/StructuredAggregateRating';

const KeywordGrouper = () => {
  const { t } = useTranslation('KeywordGrouper');

  return (
    <>
      <MetaTags title={t('seo.title')} description={t('seo.description')} />
      <StructuredService name="keyword grouper" />
      <StructuredAggregateRating
        name="SpySERP keyword grouper tool"
        description="Keyword Grouper will help you automatically segment your keywords into relevant groups with search volume and CPC data. Build topic clusters around your primary keywords"
        makesOffer="SpySERP keyword grouper"
        knowsAbout="SpySERP keyword grouper tool"
        ratingValue={5}
        bestRating={5}
        ratingCount={24}
      />
      <HeroKeywordGrouper />
      <DistributeKeywords />
      <KeywordsCompetitiveness />
      <ClusterGroups />
      <ClustersGroupsTools />
      <ExportClusters />
      <HowClusteringWork />
      <Pricing bg={6} />
      <KeywordGrouperFeatures />
      <Brands />
      <KeywordsGrouperFAQ />
    </>
  );
};

export default KeywordGrouper;
