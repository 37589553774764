import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import ContentSection from '../../ContentSection';
import GetStartedButton from '../../GetStartedButton';

function HowClusteringWork() {
  const { t } = useTranslation('KeywordGrouper');

  const title = t('HowClusteringWork.title');
  const description = t('HowClusteringWork.description');

  const steps = [
    {
      className: 'count circle-md bg-red mx-auto mb-5 text-white gr-text-5',
      title: t('HowClusteringWork.step1.title'),
      text: t('HowClusteringWork.step1.text'),
    },
    {
      className: 'circle-md bg-green mx-auto mb-5 text-white gr-text-5',
      title: t('HowClusteringWork.step2.title'),
      text: t('HowClusteringWork.step2.text'),
    },
    {
      className:
        'circle-md bg-primary mx-auto mb-5 bg-primary text-white gr-text-5',
      title: t('HowClusteringWork.step3.title'),
      text: t('HowClusteringWork.step3.text'),
    },
  ];

  return (
    <ContentSection title={title} description={description} background={5}>
      <Row style={{ marginBottom: '-36px' }}>
        <Col xs={2} />
        <Col xs={8}>
          <div
            className="kwg-dots"
            data-aos="zoom-in"
            data-aos-duration="900"
            data-aos-delay="400"
          />
        </Col>
        <Col xs={2} />
      </Row>

      <Row>
        {steps.map((step, index) => (
          <Col
            key={step.title}
            sm={12}
            lg={4}
            data-aos="zoom-in"
            data-aos-duration="900"
            data-aos-delay={400 * index}
            className="text-center"
          >
            <div className={step.className}>{index + 1}</div>
            <h3 className="gr-text-7 text-blackish-blue">{step.title}</h3>
            <p className="gr-text-8">{step.text}</p>
          </Col>
        ))}
      </Row>

      <p className="gr-text-8 mb-0 text-center px-20 my-10">
        {t('HowClusteringWork.description2')}
      </p>

      <div className="text-center">
        <GetStartedButton />
      </div>
    </ContentSection>
  );
}

export default HowClusteringWork;
