import { Col, Row } from 'react-bootstrap';
import React from 'react';
import ContentSection from '../../ContentSection';
import { useTranslation } from 'react-i18next';
import WatchIcon from '../../../assets/icons/font-awesome/solid/watch.svg';
import ExpandIcon from '../../../assets/icons/font-awesome/solid/expand.svg';
import DollarIcon from '../../../assets/icons/font-awesome/solid/dollar-sign.svg';

function KeywordGrouperFeatures() {
  const { t } = useTranslation('KeywordGrouper');
  const title = t('KeywordGrouperFeatures.title');

  const data = [
    {
      icon: WatchIcon,
      title: t('KeywordGrouperFeatures.feature1.title'),
      description: t('KeywordGrouperFeatures.feature1.description'),
    },
    {
      icon: ExpandIcon,
      title: t('KeywordGrouperFeatures.feature2.title'),
      description: t('KeywordGrouperFeatures.feature2.description'),
    },
    {
      icon: DollarIcon,
      title: t('KeywordGrouperFeatures.feature3.title'),
      description: t('KeywordGrouperFeatures.feature3.description'),
    },
  ];

  return (
    <ContentSection title={title} background={2}>
      <Row className="justify-content-md-center">
        {data.map((feature) => (
          <Col lg={4} key={feature.title}>
            <div className="svg-color-primary mb-5">
              <feature.icon height={48} />
            </div>

            <h3 className="gr-text-7 text-blackish-blue">{feature.title}</h3>

            <p className="gr-text-9 gr-color-blackish-blue-opacity-7 py-5">
              {feature.description}
            </p>
          </Col>
        ))}
      </Row>
    </ContentSection>
  );
}

export default KeywordGrouperFeatures;
