import React from 'react';
import { Row, Col } from 'react-bootstrap';

import GetStartedButton from '../../GetStartedButton';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import ContentSection from '../../ContentSection';

const ClustersGroupsTools = () => {
  const { t } = useTranslation('KeywordGrouper');
  const title = t('ClustersGroupsTools.title');
  const description = t('ClustersGroupsTools.description');

  return (
    <ContentSection>
      <Row
        className="align-items-center"
        itemScope={true}
        itemType="https://schema.org/ImageObject"
      >
        <Col
          lg="7"
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          <StaticImage
            src="../../../assets/image/keyword-grouper/group4_web.png"
            width={635}
            sizes="(min-width: 635px) 635px, (min-width: 360px) 330px, 100vw"
            breakpoints={[330, 635, 990, 1270]}
            placeholder="blurred"
            alt={title}
            className="shadow-1"
            itemProp="contentUrl"
          />
        </Col>

        <Col lg="5">
          <h2 className="title gr-text-4 mb-7 mt-7" itemProp="name">
            {title}
          </h2>
          <p className="gr-text-8" itemProp="description">
            {description}
          </p>

          <GetStartedButton />
        </Col>
      </Row>
    </ContentSection>
  );
};

export default ClustersGroupsTools;
