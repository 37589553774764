import Hero from '../../Hero';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { Button, Modal } from 'react-bootstrap';
import PlayIcon from '../../../assets/icons/font-awesome/regular/play-circle.svg';
import { CloseButton } from '../../ModalVideo';

function HeroKeywordGrouper() {
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const { t, i18n } = useTranslation('KeywordGrouper');
  const { language } = i18n;
  const title = t('HeroKeywordGrouper.title');
  const subtitle = t('HeroKeywordGrouper.subtitle');
  const description = t('HeroKeywordGrouper.description');

  let videoId = 'f4E0UBn2kVk';
  if (language === 'ru') {
    videoId = 'aLmnE7ph3PM';
  }

  return (
    <>
      <Hero
        title={title}
        subtitle={subtitle}
        description={description}
        hideSignupButton
      >
        <div
          className="mt-10 px-md-10 text-center"
          itemScope={true}
          itemType="https://schema.org/ImageObject"
        >
          <meta itemProp="name" content={title} />
          <meta itemProp="description" content={description} />
          <StaticImage
            src="../../../assets/image/keyword-grouper/grouper.png"
            width={911}
            breakpoints={[330, 635, 911, 1822]}
            loading="eager"
            placeholder="blurred"
            className="shadow-1 mb-3"
            alt={title}
            itemProp="contentUrl"
          />

          <Button variant="link" onClick={() => setIsVideoVisible(true)}>
            <span className="svg-color-primary mr-3 mb-1">
              <PlayIcon width={24} />
            </span>
            {t('HeroKeywordGrouper.tourButtonText')}
          </Button>
        </div>
      </Hero>

      <Modal
        className="modal-video"
        size="lg"
        centered
        show={isVideoVisible}
        onHide={() => setIsVideoVisible(false)}
      >
        <Modal.Body className="text-center position-relative">
          <CloseButton onClick={() => setIsVideoVisible(false)} />

          <div className="h-100 d-flex align-items-center w-100">
            <div className="modal-video-container loaded">
              <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${videoId}?&autoplay=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={title}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default HeroKeywordGrouper;
